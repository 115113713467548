<template>
  <div class="project-sales-detail">
    <!-- Project Info -->
    <main-section
      class="card"
      :salesData="sales"
      :canCancel="!isAssigned"
      :canExtendSPADueDate="!isAssigned"
      @cancelProjectSales="cancelProjectSales"
      @extendSPADueDate="openExtendSPADueDateModal"
    ></main-section>

    <!-- TODO: Phase Specific Info -->
    <!-- <booking-actions
        v-slot="{
          openApproveModal,
          openRejectModal,
          openCancelModal,
          viewRejectedReason
        }"
      >
        <booking-info
          v-if="sales.status === PHASE.BOOKING"
          class="card mt-3"
          :booking="sales.projectPurchaseBooking"
          :downloadFileAPI="downloadAPI"
          @openApproveModal="openApproveModal"
          @openRejectModal="openRejectModal"
          @openCancelModal="openCancelModal"
          @viewRejectedReason="viewRejectedReason"
        ></booking-info>
      </booking-actions> -->

    <!-- ================= Actions available across phases ================= -->
    <!-- Update Sales Form & IC for Buyer -->
    <div v-if="sales.projectPurchaseBooking" class="card p-2 mt-3">
      <booking-documents
        :booking="sales.projectPurchaseBooking"
        :downloadFileAPI="downloadAPI"
      ></booking-documents>
      <div
        v-if="canUpdateBookingDocuments"
        class="d-flex justify-content-end mt-2"
      >
        <fd-button
          class="bordered main medium"
          @click="openEditBookingDocumentsModal"
        >
          Edit Booking Documents
        </fd-button>
      </div>
    </div>

    <!-- Buyer Info -->
    <buyer-info class="card mt-3" :reservation="reservation"></buyer-info>

    <!-- Agent Info -->
    <agent-info v-if="agent" class="card mt-3" :agencyUser="agent"></agent-info>

    <!-- Banker Info -->
    <banker-info
      v-if="sales.bankLoans.length > 0"
      class="card mt-3"
      :bankLoans="sales.bankLoans"
      :selectedBanker="selectedBanker"
    ></banker-info>

    <!-- Lawyer Info -->
    <lawyer-info
      v-if="sales.projectPurchaseLawyer"
      class="card mt-3"
      :lawyerData="sales.projectPurchaseLawyer"
    >
    </lawyer-info>

    <!-- Reservation Form Info -->
    <div class="card mt-3">
      <reservation-info :projectPurchase="sales"></reservation-info>
    </div>

    <!-- Tracking Log -->
    <tracking-log :trackingLog="progress"></tracking-log>

    <!-- ================= MODAL: UPDATE BOOKING DOCUMENTS ================= -->
    <modal v-model="bookingDocumentModal.isShown">
      <edit-booking-documents
        v-if="sales.projectPurchaseBooking"
        v-bind="{
          purchaserIdentityCardOrPassport:
            sales.projectPurchaseBooking.purchaserIdentityCardOrPassport,
          saleForm: sales.projectPurchaseBooking.saleForm
        }"
        @close="bookingDocumentModal.isShown = false"
        @save="updateBookingDocuments"
      ></edit-booking-documents>
    </modal>
    <!-- ================= MODAL: UPDATE BOOKING DOCUMENTS ================= -->
    <modal v-if="sales.projectPurchaseLawyer" v-model="spaDueDateModal.isShown">
      <EditSPADueDate
        :dueDate="sales.projectPurchaseLawyer.salesPurchaseAgreementDueDate"
        @close="bookingDocumentModal.isShown = false"
        @save="updateSPADueDate"
      ></EditSPADueDate>
    </modal>
  </div>
</template>

<script>
import {
  projectSalesPhase as PHASE,
  projectBookingStatus as BOOKING_STATUS,
  projectLawyerClientStatus as LAWYER_STATUS
} from "@/enums";

export default {
  components: {
    MainSection: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/MainSection"
      ),
    BuyerInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/BuyerInfo"
      ),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    BankerInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/BankerInfo"
      ),
    LawyerInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/LawyerInfo"
      ),
    ReservationInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationInfo"
      ),
    BookingDocuments: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingDocuments"
      ),
    // BookingActions: () =>
    //   import(
    //     "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingActions"
    //   ),
    // BookingInfo: () =>
    //   import(
    //     "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingInfo"
    //   ),

    EditBookingDocuments: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/EditBookingDocuments"
      ),
    EditSPADueDate: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/EditSPADueDate"
      ),
    TrackingLog: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/TrackingLog"
      )
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    },
    getSalesDetailAPI: {
      type: Function,
      required: true
    },
    cancelProjectSalesAPI: {
      type: Function,
      required: true
    },
    downloadAPI: {
      type: Function,
      required: true
    },
    updateBookingDocumentsAPI: {
      type: Function,
      required: true
    },
    updateSPADueDateAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      PHASE,

      sales: {
        bankLoans: []
      },
      bookingDocumentModal: {
        isShown: false
      },
      spaDueDateModal: {
        isShown: false
      }
    };
  },
  computed: {
    agent() {
      return this.sales.agent;
    },
    progress() {
      return this.sales.projectPurchaseTrackingLogs;
    },
    reservation() {
      return this.sales.projectPurchaseReservation;
    },
    selectedBanker() {
      return this.sales.projectPurchaseLawyer?.selectedBanker;
    },
    canUpdateBookingDocuments() {
      return (
        this.sales.projectPurchaseBooking.status === BOOKING_STATUS.BOOKED &&
        !this.isSPASigned
      );
    },
    isSPASigned() {
      return (
        this.sales.projectPurchaseLawyer?.salesPurchaseAgreementStatus ===
          LAWYER_STATUS.SPA_SIGNED ||
        this.sales.projectPurchaseLawyer?.salesPurchaseAgreementStatus ===
          LAWYER_STATUS.SPA_STAMP_DUTY_PAID
      );
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getProjectSalesData();
    },
    openEditBookingDocumentsModal() {
      this.bookingDocumentModal.isShown = true;
    },
    openExtendSPADueDateModal() {
      this.spaDueDateModal.isShown = true;
    },
    async getProjectSalesData() {
      try {
        this.$store.commit("setIsLoading", true);
        this.isLoading = true;

        let data = await this.getSalesDetailAPI(this.$route.params.id);

        this.sales = this._.cloneDeep(data);

        this.$store.commit("setIsLoading", false);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get the sales progress data. Please try again later."
        });
        throw error;
      }
    },
    async cancelProjectSales() {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Cancel Project Sale",
        message:
          "Are you sure you want to cancel this sale? This action cannot be undone."
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.cancelProjectSalesAPI(this.$route.params.id);

          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "This project sale has been cancelled successfully."
          });

          await this.getProjectSalesData();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to cancel project sale. Please try again later."
          });
          console.log(error);
        }
      }
    },
    async updateBookingDocuments(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.updateBookingDocumentsAPI(this.sales.id, payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking documents has been updated successfully."
        });
        this.$store.commit("setIsLoading", false);

        this.bookingDocumentModal.isShown = false;
        this.getProjectSalesData();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update booking documents. Please try again later."
        });
      }
    },
    async updateSPADueDate(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.updateSPADueDateAPI(this.sales.id, payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "SPA due date updated successfully."
        });
        this.$store.commit("setIsLoading", false);

        this.spaDueDateModal.isShown = false;
        this.getProjectSalesData();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update SPA due date. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
